
import { defineComponent, ref, onMounted, Ref } from "vue";

import ptBr from "element-plus/lib/locale/lang/pt-br";
import useEmitter from "@/composables/Emmiter";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import Api from "@/services/Api";

interface StoreFilter {
  grupo: any[];
  loja: any;
  data: any[];
  regiao: any[];
}

// interface ArrayLojas {
//   nome: string;
//   codConcessionaria: number
// }

export default defineComponent({
  name: "FilterDashboardGerencia",
  components: {
    FilterBase,
  },

  setup(props, { emit }) {
    const emitter = useEmitter();

    const closeModal = ref(false);
    const storeGroups: Ref<any[]> = ref([]);
    const lojas: Ref<any[]> = ref([]);
    const storeFilter: Ref<StoreFilter> = ref({ grupo: [], loja: null, data: [], regiao: [] });
    const valorDataInicial: Ref<Date[]> = ref([]);

    async function getLojas() {
      const idLojaStorage: any = Number(localStorage.getItem('id_loja'));
      const res = await Api.get("getAllStores");
      lojas.value = res.data.lojas;

      if(idLojaStorage){
        storeFilter.value.loja = idLojaStorage
      }else{
        localStorage.setItem('id_loja', res.data.lojas[0].codConcessionaria)
        storeFilter.value.loja = res.data.lojas[0].codConcessionaria
      }
    }

    async function enviarEmit() {
      await emitter.emit("filter-dashboard-gerencia", {
        dataFilter: storeFilter.value,
      });
      closeModal.value = !closeModal.value;
    }

    // emitter.on("DashboardGerencia", async params => {
    //   storeFilter.value.data = params.dataSelecionada;
    // });

    const getMonthRange = () => {
      try {
        //essa parte pega o ultimo dia do mes atual, e o primeiro dia do mes atual.
        const today = new Date(); // Obtém a data atual
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // Define o primeiro dia do mês atual
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Define o último dia do mês atual

        valorDataInicial.value = [firstDayOfMonth, lastDayOfMonth]; // Define o intervalo do mês atual como um array em dataAtualMounted
        
      } catch (error) {
        console.log(error);
      } finally {
        storeFilter.value.data = valorDataInicial.value;
        enviarEmit()
      }
    };

    onMounted(async () => {
      await getLojas();
      getMonthRange()
    });

    return { storeFilter, storeGroups, lojas, enviarEmit, closeModal, ptBr };
  },
});
